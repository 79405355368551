<template>
  <v-app>
    <v-main class="blue-grey lighten-5">
      <v-container class="mt-12" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" align="center">
            <v-img src="../assets/logo.png" width="193px" heght="195px"></v-img>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <h1 class="py-12 text-center display-2">{{ siteName }}</h1>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { SITE_NAME } from '@/constants'

export default {
  name: 'AuthIndex',
  data() {
    return {
      siteName: SITE_NAME
    }
  }
}
</script>
